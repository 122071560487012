import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layouts/admin/admin.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { AuthGuard } from './guard/auth.guard';
import { SetupAuthGuard } from './setupGuard/auth.guard';
const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'memocodes',
        pathMatch: 'full'
      },

      {
        path: 'memocodes',
        loadChildren: () => import('./memocodes/memocodes.module').then(m => m.MemocodesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'memocode-details',
        loadChildren: () => import('./memocodedetails/memocodedetails.module').then(m => m.MemocodedetailsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin-customers',
        loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin-customer-details',
        loadChildren: () => import('./customerdetails/customerdetails.module').then(m => m.CustomerdetailsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin-users',
        loadChildren: () => import('./admin-users/admin-users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin-userdetails',
        loadChildren: () => import('./admin-userdetails/admin-userdetails.module').then(m => m.AdminUserDetailsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard]
      },
      //
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [SetupAuthGuard]
      }, {
        path: 'report',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'setup',
        loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule)
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'memocodes'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
