<div id="pcoded" (window:resize)="onResize($event)" class="pcoded iscollapsed" theme-layout="vertical"
  vertical-placement="left" vertical-layout="wide" [attr.pcoded-device-type]="deviceType"
  [attr.vertical-nav-type]="verticalNavType" [attr.vertical-effect]="verticalEffect" vnavigation-view="view1">
  <div class="pcoded-overlay-box"></div>
  <div class="pcoded-container navbar-wrapper">
    <nav class="navbar header-navbar pcoded-header" header-theme="theme4" pcoded-header-position="fixed">
      <div class="navbar-wrapper">
        <div class="navbar-logo" navbar-theme="theme4" style="background-color: #fff !important;">
          <a class="mobile-menu" id="mobile-collapse" href="javascript:;" (click)="toggleOpened()"
            [exclude]="'#main_navbar'" (clickOutside)="onClickedOutside($event)">
            <i class="ti-menu"></i>
          </a>
          <a [routerLink]="['/']">
            <img class="img-fluid" style="max-width: 70% !important;" src="assets/sidemenu.png" alt="Theme-Logo" />
          </a>
          <a (click)="onMobileMenu()" class="mobile-options">
            <i class="ti-more"></i>
          </a>
        </div>

        <div class="navbar-container">
          <div>
            <ul class="nav-left">
              <li>
                <div class="sidebar_toggle"><a href="javascript:;" (click)="toggleOpened()"><i
                      class="ti-menu f-18"></i></a></div>
              </li>
            </ul>
            <ul [@mobileMenuTop]="isCollapsedMobile" class="nav-right" [ngClass]="isCollapsedMobile">

              <li class="user-profile header-notification">
                <a [routerLink]="['/']">
                  <img src="assets/images/user.png" alt="User-Profile-Image">
                  <span>{{getUsernName()}}</span>
                  <i class="ti-angle-down"></i>
                </a>
                <ul class="show-notification profile-notification">
                  <li>
                    <a [routerLink]="['/profile']">
                      <i class="ti-id-badge"></i> Perfil
                    </a>
                  </li>
                  <li>
                    <a (click)="logout()">
                      <i class="ti-layout-sidebar-left"></i> {{'Logout' | translate}}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>


    <!-- rightbar chat end-->
    <div class="pcoded-main-container" style="margin-top: 56px;">
      <div class="pcoded-wrapper">
        <nav id="main_navbar" class=" pcoded-navbar" (clickOutside)="onClickedOutside($event)"
          [exclude]="'#mobile-collapse'" navbar-theme="themelight1" active-item-theme="theme4" sub-item-theme="theme2"
          active-item-style="style0" pcoded-navbar-position="fixed" pcoded-header-position="fixed">
          <div class="sidebar_toggle"><a href="javascript:;"><i class="icon-close icons"></i></a></div>
          <div class="pcoded-inner-navbar main-menu" appAccordion>
            <perfect-scrollbar [style.max-width]="'100%'" [style.max-height]="'calc(100vh - 56px)'" [config]="config">
              <div>
                <div *ngFor="let asideItems of getMemu()"> 
                  <div class="pcoded-navigatio-lavel" menu-title-theme="theme5">{{getName(asideItems.label)}} </div>
                  <ul class="pcoded-item pcoded-left-item" item-border="none" item-border-style="solid"
                    subitem-border="solid" *ngFor="let asideItem of asideItems.main" appAccordionLink >
                    

                    <li [routerLinkActive]="['active']" *ngIf="asideItem.type === 'link'" appAccordionLink >
                        <a [routerLink]="['/', asideItem.state]" target="{{asideItem.target ? '_blank' : '_self'}}"
                          appAccordionToggle> 
                          <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i></span>
                          <span class="pcoded-mtext">{{getName(asideItem.name)}}</span>
                        </a>
                    </li>
                  </ul>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </nav>
        <div class="pcoded-content">
          <div class="pcoded-inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <app-title></app-title>
                <app-breadcrumbs></app-breadcrumbs>
                <div class="page-body">
                  <router-outlet>
                    <app-spinner></app-spinner>
                  </router-outlet>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>