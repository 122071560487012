// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyC_kvDwCaajjNUWR38UnTOBz__jE4rYfnQ",
    authDomain: "memocode-fbc97.firebaseapp.com",
    projectId: "memocode-fbc97",
    storageBucket: "memocode-fbc97.appspot.com",
    messagingSenderId: "822426133386",
    appId: "1:822426133386:web:5db9ed34a1a5ae57f05e73",
    measurementId: "G-SGSMZ9E8RS"
  },
  onesignal: {
    appId: '',
    googleProjectNumber: '',
    restKey: ''
  },
  stripe: {
    sk: ''
  },
  general: {
    symbol: 'Bs. ',
    code: 'BOB'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
