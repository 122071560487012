import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApisService } from '../services/apis.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    type = '';
    constructor(private authServ: ApisService, private router: Router) { 
        this.type = localStorage.getItem('type');
    }

    canActivate(route: ActivatedRouteSnapshot): any {
        // /// You can use this one for better security
        // /// You can use this one for better security
        // /// You can use this one for better security
        return this.authServ.checkAuth().then(user => {
            if (user) {
                if(route.routeConfig.path == 'profile'){
                    return true;
                }
                if(this.type == 'Administrador'){
                    if(route.routeConfig.path == 'admin-users' || route.routeConfig.path == 'admin-userdetails'){
                        this.router.navigate(['/memocodes']);
                    }else{
                        return true;
                    }
                } else  if(this.type == 'Usuario' ){
                    if (route.routeConfig.path == 'admin-users' || route.routeConfig.path == 'admin-userdetails' ||
                            route.routeConfig.path == 'admin-customers' || route.routeConfig.path == 'admin-customer-details'){
                         this.router.navigate(['/memocodes']);
                    }else{
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                this.router.navigate(['/auth/login']);
            }
        }).catch(error => {
            console.log(error);
            this.router.navigate(['/auth/login']);
        });


        // ///// Less Secure but faster
        // const uid = localStorage.getItem('uid');
        // console.log('uid', localStorage.getItem('uid'));
        // if (uid && uid != null && uid !== 'null') {
        //     return true;
        // }
        // this.router.navigate(['/auth/login']);
        // return false;
    }
}
