import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Main',
    main: [
      {
        state: 'memocodes',
        state2: 'memocode-details',
        state3: 'memocode',
        name: 'Memocodes',
        type: 'link',
        icon: 'ti-home'
      },
      {
        state: 'admin-customers',
        state2: 'admin-customer-details',
        state3: 'customer',
        name: 'Customers',
        type: 'link',
        icon: 'ti-notepad'
      },
      {
        state: 'admin-users',
        state2: 'admin-userdetails',
        state3: 'user',
        name: 'Administration',
        type: 'link',
        icon: 'ti-id-badge'
      }
    ],
  },
];

const MENUITEMSUSUARIO = [
  {
    label: 'Main',
    main: [
      {
        state: 'memocodes',
        substate: '',
        name: 'Memocodes',
        type: 'link',
        icon: 'ti-home'
      }
    ],
  },
];

const MENUITEMSADMINISTRADOR = [
  {
    label: 'Main',
    main: [
      {
        state: 'memocodes',
        substate: '',
        name: 'Memocodes',
        type: 'link',
        icon: 'ti-home'
      },
      {
        state: 'admin-customers',
        substate: 'admin-customer-details',
        name: 'Customers',
        type: 'link',
        icon: 'ti-notepad'
      }
    ],
  },
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  getAllUsuario(): Menu[] {
    return MENUITEMSUSUARIO;
  }

  getAllAdministrador(): Menu[] {
    return MENUITEMSADMINISTRADOR;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
